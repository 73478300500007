'use client';

import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';



import { Check, WarningCircle, X } from '@fsg/icons';





export type NotificationProps = {
  message?: string
  description?: string
  type?: 'alert' | 'warning' | 'success'
  duration?: number | null
  position?: {
    top: number
    right: number
    bottom: number
    left: number
  }
  portal?: string | null
  render?: () => React.ReactNode
  showClose?: boolean
}

function getTypeStyle(type: NotificationProps['type']) {
  const typeStyles = {
    alert: 'border-gray-300 bg-white text-black',
    warning: 'border-red-800 bg-red-100 text-red-800',
    success: 'border-green-800 bg-green-100 text-green-800',
  }
  switch (type) {
    case 'alert':
      return typeStyles.alert
    case 'success':
      return typeStyles.success
    case 'warning':
      return typeStyles.warning
    case undefined:
      return 'bg-white'
  }
}

function getIconFromType(type: NotificationProps['type']) {
  switch (type) {
    case 'alert':
      return null
    case 'success':
      return <Check className="inline" />
    case 'warning':
      return <WarningCircle className="inline" />
    case undefined:
      return ''
  }
}

export const Notification = ({
  message,
  description,
  duration = 3000,
  position,
  type,
  render,
  portal = null,
  showClose = true,
}: NotificationProps) => {
  const { api } = useNotification()
  const [shown, setShown] = useState(true)
  const notificationRef = useRef<HTMLDialogElement | null>(null)
  const portalRef = useRef<HTMLDivElement | null>(null)
  const [mounted, setMounted] = useState(false)
  function closeNotification(event: any) {
    event.preventDefault()
    setShown(false)
    api.close()
  }

  useEffect(() => {
    portalRef.current = portal ? document.querySelector<HTMLDivElement>(portal) : null
    setMounted(true)
  }, [portal])

  useEffect(() => {
    const notifElement = notificationRef.current

    if (!notifElement) return

    if (shown) {
      notifElement.show()
    } else {
      notifElement.close()
    }
  }, [shown])

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (duration) {
      timer = setTimeout(() => {
        setShown(false)
        api.close()
      }, duration)
    }
    return () => {
      duration && clearTimeout(timer)
    }
  }, [duration, api])

  if (!render && message === undefined) throw new Error('If not using render prop, message should be provided.')

  const Icon = () => getIconFromType(type)

  const content = render ? (
    render()
  ) : (
    <>
      <div className="flex justify-between">
        <h2 className="flex items-center gap-sm text-sm">
          <div className="flex items-center text-xl">
            <Icon />
          </div>
          {message}
        </h2>
        {showClose ? (
          <button onClick={closeNotification} id="toast-close-trigger" className="text-xl" type="button">
            <X />
          </button>
        ) : null}
      </div>
      <p className="text-sm">{description}</p>
    </>
  )

  const style: React.CSSProperties = { top: '10px', right: '10px', pointerEvents: 'auto', ...position }

  return shown ? (
    portal ? (
      mounted && portalRef.current ? (
        createPortal(
          <>
            <div
              className={`absolute z-[60] w-[300px] translate-y-0 transform rounded-md border p-lg opacity-100 shadow-xl transition-all duration-500 ease-in-out ${getTypeStyle(
                type,
              )}`}
              style={style}
            >
              {content}
            </div>
          </>,
          portalRef.current,
        )
      ) : null
    ) : (
      <dialog ref={notificationRef}>
        <div
          className={`fixed z-[60] w-[300px] translate-y-0 transform rounded-xs border px-md py-sm opacity-100 shadow-xl transition-all duration-500 ease-in-out ${getTypeStyle(
            type,
          )}`}
          style={style}
        >
          {content}
        </div>
      </dialog>
    )
  ) : null
}

type NotificationContextType = {
  api: {
    notify: (notification: NotificationProps) => void
    close: () => void
  }
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined)

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [notification, setNotification] = useState<NotificationProps | null>(null)

  const value = useMemo(() => {
    return {
      api: {
        notify: (notification: NotificationProps) => {
          setNotification(notification)
        },
        close: () => {
          setNotification(null)
        },
      },
    }
  }, [])

  return (
    <NotificationContext.Provider value={value}>
      {children}
      {notification && <Notification {...notification} />}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider')
  }
  return context
}